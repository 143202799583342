import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useCookieConsent } from '../context/CookieConsentContext';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  const { consent, updateConsent, savePreferences, hasInteracted } = useCookieConsent();
  const [showDetails, setShowDetails] = useState(false);

  if (hasInteracted) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        className="fixed bottom-0 left-0 right-0 z-50 bg-white dark:bg-brand-dark shadow-lg border-t border-gray-200 dark:border-gray-700"
      >
        <div className="max-w-7xl mx-auto p-4 md:p-6">
          {!showDetails ? (
            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
              <div className="text-sm text-black dark:text-white">
                <p className="mb-2">
                  We use cookies to enhance your browsing experience and analyze our traffic. 
                  We only use cookies with your consent, except for essential cookies necessary for the website to function.
                </p>
                <p className="text-xs">
                  For more information, please read our{' '}
                  <Link to="/legal#privacy" className="text-brand-blue hover:underline">
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-2">
                <button
                  onClick={() => setShowDetails(true)}
                  className="text-sm px-4 py-2 text-brand-blue hover:underline"
                >
                  Customize
                </button>
                <button
                  onClick={() => {
                    updateConsent('analytics', true);
                    updateConsent('marketing', true);
                    updateConsent('preferences', true);
                    savePreferences();
                  }}
                  className="text-sm px-4 py-2 bg-brand-blue text-white rounded hover:bg-brand-blue/90"
                >
                  Accept All
                </button>
                <button
                  onClick={() => {
                    updateConsent('analytics', false);
                    updateConsent('marketing', false);
                    updateConsent('preferences', false);
                    savePreferences();
                  }}
                  className="text-sm px-4 py-2 bg-gray-200 dark:bg-gray-700 text-black dark:text-white rounded hover:bg-gray-300 dark:hover:bg-gray-600"
                >
                  Reject All
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-black dark:text-white">Cookie Preferences</h3>
                  <button
                    onClick={() => setShowDetails(false)}
                    className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  >
                    Back
                  </button>
                </div>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-black dark:text-white">Essential Cookies</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Required for the website to function properly. Cannot be disabled.
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={true}
                      disabled
                      className="rounded text-brand-blue"
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-black dark:text-white">Analytics Cookies</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Help us understand how visitors interact with our website through anonymous data collection.
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={consent.analytics}
                      onChange={(e) => updateConsent('analytics', e.target.checked)}
                      className="rounded text-brand-blue"
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-black dark:text-white">Marketing Cookies</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Used for personalized advertisements and tracking their effectiveness.
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={consent.marketing}
                      onChange={(e) => updateConsent('marketing', e.target.checked)}
                      className="rounded text-brand-blue"
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-black dark:text-white">Preference Cookies</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Remember your settings and preferences for a better browsing experience.
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={consent.preferences}
                      onChange={(e) => updateConsent('preferences', e.target.checked)}
                      className="rounded text-brand-blue"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-2">
                <button
                  onClick={savePreferences}
                  className="text-sm px-4 py-2 bg-brand-blue text-white rounded hover:bg-brand-blue/90"
                >
                  Save Preferences
                </button>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CookieBanner; 