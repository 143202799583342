import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { ProductReview } from '../../types/product-variant';
import Lightbox from './Lightbox'; // Import the Lightbox component

interface ProductReviewsProps {
  productId: string;
  reviews: ProductReview[];
  isOneOfKind?: boolean;
  collection?: string;
}

const ProductReviews = ({ productId, reviews, isOneOfKind, collection }: ProductReviewsProps) => {
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [reviewForm, setReviewForm] = useState({
    name: '',
    email: '',
    rating: 5,
    review: '',
    orderNumber: ''
  });
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const relevantReviews = isOneOfKind
    ? reviews.filter(review => review.collection === collection)
    : reviews.filter(review => review.productId === productId);

  const hasReviews = relevantReviews.length > 0;
  const averageRating = hasReviews
    ? relevantReviews.reduce((acc, rev) => acc + rev.rating, 0) / relevantReviews.length
    : 0;

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  const handleSubmitReview = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/submit-review', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...reviewForm,
          productId,
          collection // Include collection if needed
        }),
      });
      
      if (response.ok) {
        setShowReviewForm(false);
        // Optionally reset the review form
        setReviewForm({
          name: '',
          email: '',
          rating: 5,
          review: '',
          orderNumber: ''
        });
        // Show success message or update state
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  return (
    <div className="mt-16 mb-8">
      <div className="flex items-center justify-center gap-2 mb-6">
        <h2 className="text-2xl font-semibold text-center">What Our Customers Think:</h2>
        {isOneOfKind && (
          <div className="relative">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="text-gray-500 cursor-help h-5"
              onMouseEnter={() => setShowInfoTooltip(true)}
              onMouseLeave={() => setShowInfoTooltip(false)}
            />
            {showInfoTooltip && (
              <div className="absolute z-10 w-72 p-3 text-sm bg-white dark:bg-gray-800 rounded-lg shadow-lg -right-2 top-6 border">
                As this is a one-of-a-kind piece, we're showing reviews from similar pieces in our {collection} collection.
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center gap-2">
          {hasReviews ? (
            <>
              <div className="text-3xl font-bold">{averageRating.toFixed(1)}</div>
              <div className="flex text-yellow-500">
                {[...Array(5)].map((_, i) => (
                  <FontAwesomeIcon 
                    key={i} 
                    icon={faStar} 
                    className={`h-5 ${i < Math.round(averageRating) ? 'text-yellow-500' : 'text-gray-300'}`}
                  />
                ))}
              </div>
              <span className="text-gray-500">({relevantReviews.length} reviews)</span>
            </>
          ) : (
            <span className="text-gray-500">No reviews yet</span>
          )}
        </div>
        
        <button
          onClick={() => setShowReviewForm(true)}
          className="bg-brand-blue text-white px-4 py-2 rounded hover:bg-brand-blue/90 transition-colors"
        >
          {hasReviews ? 'Write a Review' : 'Be the First to Review'}
        </button>
      </div>

      {/* Review List */}
      <div className="space-y-6">
        {hasReviews ? (
          relevantReviews.map((review) => (
            <div key={review.id} className="border-b pb-6">
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center gap-2">
                    <span className="font-semibold">{review.customerName}</span>
                    {review.verifiedPurchase && (
                      <span className="text-green-500 text-sm">Verified Purchase</span>
                    )}
                  </div>
                  <div className="flex text-yellow-500 my-1">
                    {[...Array(5)].map((_, i) => (
                      <FontAwesomeIcon 
                        key={i} 
                        icon={faStar} 
                        className={`h-4 ${i < review.rating ? 'text-yellow-500' : 'text-gray-300'}`}
                      />
                    ))}
                  </div>
                </div>
                <span className="text-gray-500 text-sm">
                  {new Date(review.date).toLocaleDateString()}
                </span>
              </div>
              <p className="mt-2">{review.review}</p>
              {review.media && (
                <div className="flex gap-2 mt-4">
                  {review.media.map((media, index) => (
                    media.type === 'image' ? (
                      <img 
                        key={index}
                        src={media.url} 
                        alt={`Review media ${index + 1}`} 
                        className="w-24 h-24 object-cover rounded cursor-pointer"
                        onClick={() => handleImageClick(media.url)} // Open lightbox on click
                      />
                    ) : (
                      <video 
                        key={index}
                        src={media.url} 
                        className="w-24 h-24 object-cover rounded"
                        controls
                      />
                    )
                  ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-gray-500">
            <p className="mb-2">No reviews yet for this product.</p>
            <p className="text-sm">Be the first to share your experience and get 10% off your next purchase!</p>
          </div>
        )}
      </div>

  

      {/* Lightbox for customer images */}
      <Lightbox 
        selectedImage={selectedImage} 
        images={relevantReviews.flatMap(review => review.media?.filter(m => m.type === 'image').map(m => m.url) || [])} 
        onClose={() => setSelectedImage(null)} 
      />

      {/* Review Form Modal */}
      {showReviewForm && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
        >
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-lg w-full mx-4">
            <h3 className="text-xl font-semibold mb-4">
              {isOneOfKind ? 'Share Your Experience' : 'Write a Review'}
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              {isOneOfKind 
                ? 'Your review will help other customers discover our unique pieces.'
                : 'Get 10% off your next purchase when you submit a review!'}
            </p>
            <form onSubmit={handleSubmitReview} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium mb-1">Name</label>
                <input
                  type="text"
                  id="name"
                  value={reviewForm.name}
                  onChange={(e) => setReviewForm(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
                <input
                  type="email"
                  id="email"
                  value={reviewForm.email}
                  onChange={(e) => setReviewForm(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>

              <div>
                <label htmlFor="orderNumber" className="block text-sm font-medium mb-1">Order Number</label>
                <input
                  type="text"
                  id="orderNumber"
                  value={reviewForm.orderNumber}
                  onChange={(e) => setReviewForm(prev => ({ ...prev, orderNumber: e.target.value }))}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                  placeholder="From your order confirmation email"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Rating</label>
                <div className="flex gap-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      type="button"
                      onClick={() => setReviewForm(prev => ({ ...prev, rating: star }))}
                      className={`text-2xl ${star <= reviewForm.rating ? 'text-yellow-500' : 'text-gray-300'}`}
                    >
                      ★
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <label htmlFor="review" className="block text-sm font-medium mb-1">Review</label>
                <textarea
                  id="review"
                  value={reviewForm.review}
                  onChange={(e) => setReviewForm(prev => ({ ...prev, review: e.target.value }))}
                  className="w-full px-3 py-2 border rounded-md"
                  rows={4}
                  required
                />
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <button
                  type="button"
                  onClick={() => setShowReviewForm(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-brand-blue text-white px-4 py-2 rounded hover:bg-brand-blue/90 transition-colors"
                >
                  Submit Review
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default ProductReviews; 