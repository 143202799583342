import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Product, ProductVariant } from '../../types/product-variant';
import CartActions from './CartActions';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ShippingInfo from './ShippingInfo'
import ShareButton from './ShareButton';

interface ProductInfoProps {
    product: Product;
    selectedVariant: ProductVariant | null;
    isAdded: boolean;
    localQuantity: number;
    onAddToCart: () => void;
    onQuantityChange: (quantity: number) => void;
    showVariantError: boolean;
    onVariantChange: (variantId: string) => void;
    inStock?: boolean;
    stockError: string | null;
    onWaitlistClick: () => void;
    onCustomRequestClick: () => void;
}

const ProductInfo = ({
    product,
    selectedVariant,
    isAdded,
    localQuantity,
    onAddToCart,
    onQuantityChange,
    showVariantError,
    onVariantChange,
    stockError,
    onWaitlistClick,
    onCustomRequestClick
}: ProductInfoProps) => {
    const [showWaitlistModal, setShowWaitlistModal] = useState(false);
    const [showCustomRequestModal, setShowCustomRequestModal] = useState(false);
    const [email, setEmail] = useState('');
    const [customRequest, setCustomRequest] = useState({
        name: '',
        email: '',
        message: '',
        reference: product?.name || ''
    });
    const activePrice = selectedVariant?.price ?? product?.price ?? product?.basePrice ?? 0;
    const isProductInStock = selectedVariant ? selectedVariant.inStock : (product.inStock ?? true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleWaitlistSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form submitted', { email, productName: product.name, productId: product.id });
        
        setIsSubmitting(true);
        setSubmitError(null);

        try {
            const response = await fetch('/api/waitlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    productName: product.name,
                    productId: product.id,
                    productImage: product.mainImage
                })
            });

            console.log('Response status:', response.status);
            const data = await response.json();
            console.log('Response data:', data);

            if (!response.ok) {
                throw new Error(data.error || 'Failed to submit waitlist');
            }

            setSubmitSuccess(true);
            setEmail('');
            setTimeout(() => {
                setShowWaitlistModal(false);
                setSubmitSuccess(false);
            }, 2000);
        } catch (error) {
            console.error('Submission error:', error);
            setSubmitError('Failed to join waitlist. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const MaterialTag = () => {
        if (product.variants) {
            return selectedVariant ? (
                <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                    {selectedVariant.material}
                </span>
            ) : (
                <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                    Multiple Materials Available
                </span>
            );
        }
        
        return product.material ? (
            <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                {product.material}
            </span>
        ) : null;
    };

    const VariantSelector = () => (
        product?.variants && (
            <div className="mb-6">
                <label className="block text-black dark:text-white mb-2">
                    Select Material:
                </label>
                <select 
                    className="w-full p-2 border rounded-lg bg-white dark:bg-brand-charcoal text-black dark:text-white"
                    onChange={(e) => onVariantChange(e.target.value)}
                    value={selectedVariant?.id || ''}
                >
                    <option value="">Select material</option>
                    {product.variants.map(variant => (
                        <option key={variant.id} value={variant.id}>
                            {variant.material} - €{variant.price.toFixed(2)}
                        </option>
                    ))}
                </select>
            </div>
        )
    );

    return (
        <div className="space-y-6 pb-24 md:pb-0">
            <h1 className="text-4xl font-bold text-black dark:text-white">
                {product.name}
            </h1>
            <div className="flex items-center justify-between">
                <p className="text-xl text-black dark:text-white">
                    €{activePrice.toFixed(2)}
                </p>
                <ShareButton 
                    url={window.location.href}
                    title={product.name}
                    description={product.description}
                    image={product.mainImage || product.defaultImages?.main || ''}
                />
            </div>
            {product.oneOfKind && (
                <div className="flex items-center gap-2 text-brand-blue">
                    <FontAwesomeIcon icon={faStar} className="h-4 w-4" />
                    <p className="font-semibold text-lg">
                        One of a Kind Piece
                    </p>
                </div>
            )}
            <VariantSelector />

            {!isProductInStock && (
                <div className="text-red-500 font-semibold mb-4">
                    Out of Stock
                </div>
            )}

            <CartActions
                product={product}
                isAdded={isAdded}
                localQuantity={localQuantity}
                onAddToCart={onAddToCart}
                onQuantityChange={onQuantityChange}
                showVariantError={showVariantError}
                inStock={isProductInStock}
                selectedVariant={selectedVariant}
            />

            {!isProductInStock && (
                <div className="flex flex-col gap-3 mt-4">
                    <button
                        onClick={() => setShowWaitlistModal(true)}
                        className="w-full px-4 py-2 text-sm bg-brand-blue/10 text-brand-blue rounded-lg hover:bg-brand-blue/20 transition-colors"
                    >
                        Notify me when available
                    </button>
                    <button
                        onClick={() => setShowCustomRequestModal(true)}
                        className="w-full px-4 py-2 text-sm bg-gray-100 dark:bg-brand-charcoal text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-200 dark:hover:bg-brand-dark transition-colors"
                    >
                        Request Custom Piece
                    </button>
                </div>
            )}

            {stockError && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-red-500 text-sm mt-2"
                >
                    {stockError}
                </motion.div>
            )}

            {/* Waitlist Modal */}
            <AnimatePresence>
                {showWaitlistModal && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    >
                        <div className="bg-white dark:bg-brand-charcoal p-6 rounded-lg max-w-md w-full mx-4">
                            <h3 className="text-xl font-semibold mb-4 text-black dark:text-white">
                                Get Notified
                            </h3>
                            {submitSuccess ? (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    className="text-green-500 mb-4"
                                >
                                    Successfully joined the waitlist!
                                </motion.div>
                            ) : (
                                <>
                                    <p className="mb-4 text-black dark:text-white">
                                        We'll notify you when {product.name} is back in stock.
                                    </p>
                                    <form onSubmit={handleWaitlistSubmit} className="space-y-4">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your email"
                                            className="w-full p-2 border rounded dark:bg-brand-charcoal dark:border-gray-600"
                                            required
                                            disabled={isSubmitting}
                                        />
                                        {submitError && (
                                            <p className="text-red-500 text-sm">{submitError}</p>
                                        )}
                                        <div className="flex space-x-2">
                                            <button
                                                type="submit"
                                                className="bg-brand-blue text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Submitting...' : 'Notify Me'}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setShowWaitlistModal(false)}
                                                className="bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300"
                                                disabled={isSubmitting}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Custom Request Modal */}
            <AnimatePresence>
                {showCustomRequestModal && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    >
                        <div className="bg-white dark:bg-brand-charcoal p-6 rounded-lg max-w-md w-full mx-4">
                            <h3 className="text-xl font-semibold mb-4 text-black dark:text-white">
                                Request Custom Piece
                            </h3>
                            {submitSuccess ? (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    className="text-green-500 mb-4"
                                >
                                    Successfully sent your request! We'll be in touch soon.
                                </motion.div>
                            ) : (
                                <>
                                    <p className="mb-4 text-black dark:text-white">
                                        Tell us about the piece you'd like us to create inspired by {product.name}.
                                    </p>
                                    <form onSubmit={async (e) => {
                                        e.preventDefault();
                                        setIsSubmitting(true);
                                        try {
                                            const response = await fetch('/api/custom-request', {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify(customRequest)
                                            });

                                            if (!response.ok) {
                                                throw new Error('Failed to submit request');
                                            }

                                            setSubmitSuccess(true);
                                            setCustomRequest({
                                                name: '',
                                                email: '',
                                                message: '',
                                                reference: product?.name || ''
                                            });
                                            setTimeout(() => {
                                                setShowCustomRequestModal(false);
                                                setSubmitSuccess(false);
                                            }, 2000);
                                        } catch (error) {
                                            setSubmitError('Failed to submit request. Please try again.');
                                        } finally {
                                            setIsSubmitting(false);
                                        }
                                    }} className="space-y-4">
                                        <input
                                            type="text"
                                            placeholder="Your Name"
                                            value={customRequest.name}
                                            onChange={(e) => setCustomRequest({ ...customRequest, name: e.target.value })}
                                            className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                                            required
                                        />
                                        <input
                                            type="email"
                                            placeholder="Your Email"
                                            value={customRequest.email}
                                            onChange={(e) => setCustomRequest({ ...customRequest, email: e.target.value })}
                                            className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                                            required
                                        />
                                        <textarea
                                            placeholder="Tell us about your vision..."
                                            value={customRequest.message}
                                            onChange={(e) => setCustomRequest({ ...customRequest, message: e.target.value })}
                                            className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                                            rows={4}
                                            required
                                        />
                                        <div className="flex space-x-2">
                                            <button
                                                type="submit"
                                                className="bg-brand-blue text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Sending...' : 'Send Request'}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setShowCustomRequestModal(false)}
                                                className="bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300"
                                                disabled={isSubmitting}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Tags Section */}
            <div className="flex flex-wrap gap-2">
                <MaterialTag />
                <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                    {product.category}
                </span>
            </div>

            {/* Description Label */}
            <h2 className="text-2xl font-semibold text-black dark:text-white">
                Description:
            </h2>
            {/* Description */}
            <div className="space-y-2">
                <p className="text-black dark:text-white">
                    {product.description}
                </p>
            </div>
            
            {/* Shipping Information */}
            <ShippingInfo />
        </div>
    );
};

export default ProductInfo; 