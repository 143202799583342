export const cleanupCookies = (consent: {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
  preferences: boolean;
}) => {
  // Get all cookies
  const cookies = document.cookie.split(';');
  
  // Define patterns for different cookie types
  const analyticsCookies = ['_ga', '_gid', '_gat'];
  const marketingCookies = ['_fbp', '_fbc'];
  
  cookies.forEach(cookie => {
    const [name] = cookie.split('=').map(c => c.trim());
    
    // Remove analytics cookies if consent withdrawn
    if (!consent.analytics && analyticsCookies.some(pattern => name.startsWith(pattern))) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
    
    // Remove marketing cookies if consent withdrawn
    if (!consent.marketing && marketingCookies.some(pattern => name.startsWith(pattern))) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
    
    // Remove preference cookies if consent withdrawn
    if (!consent.preferences && name.startsWith('pref_')) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  });
  
  // Clear localStorage items based on consent
  if (!consent.preferences) {
    // Keep only necessary items
    const necessaryItems = ['cookieConsent']; // Add other necessary items
    Object.keys(localStorage).forEach(key => {
      if (!necessaryItems.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }
}; 