import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useCookieConsent } from '../context/CookieConsentContext';

const AnalyticsWrapper: React.FC = () => {
  const { consent } = useCookieConsent();

  return (
    <>
      {/* Analytics only loads if analytics consent is given */}
      {consent.analytics && <Analytics mode={process.env.NODE_ENV === 'production' ? 'production' : 'development'} />}
      
      {/* Speed Insights is considered essential for site performance monitoring */}
      <SpeedInsights />
    </>
  );
};

export default AnalyticsWrapper; 