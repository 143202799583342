import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const NotFound: React.FC = () => {
  return (
    <>
      <SEO 
        title="404 - Page Not Found"
        description="The page you're looking for doesn't exist. Let's get you back to shopping."
        noindex={true}
      />
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl font-bold mb-4 text-brand-dark dark:text-brand-white">
          404 - Page Not Found
        </h1>
        <p className="text-lg mb-8 text-gray-600 dark:text-gray-300">
          Oops! The page you're looking for seems to have vanished like a missing earring.
        </p>
        <Link 
          to="/"
          className="inline-flex items-center gap-2 px-6 py-3 bg-brand-blue text-white rounded-lg hover:bg-brand-dark transition-colors"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />
          <span>Back to Home</span>
        </Link>
      </div>
    </>
  );
};

export default NotFound; 