import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import ProductCard from '../components/ProductCard';
import { calculateTotal } from '../functions/calculateTotal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import SEO from '../components/SEO';
import { loadStripe } from '@stripe/stripe-js';
import { trackInitiateCheckout, trackPurchase } from '../components/MetaPixel';
import getStripe from '../utils/stripe-config';

const Cart: React.FC = () => {
  const { items, email } = useCart();
  const total = calculateTotal(items);
  const hasOneOfKindItem = items.some(item => item.oneOfKind);
  const FREE_SHIPPING_THRESHOLD = 200;
  const remainingForFreeShipping = FREE_SHIPPING_THRESHOLD - total;

  const handleCheckout = async () => {
    try {
      const stripe = await getStripe();
      
      if (!stripe) {
        throw new Error('Failed to initialize Stripe');
      }

      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const { sessionId } = await response.json();

      // Use location.replace for more consistent redirect behavior
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (error) {
      console.error('Checkout error:', error);
      // Handle error display to user
    }
  };

  if (items.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-[80vw] text-center">
        <h1 className="text-3xl font-bold text-brand-dark dark:text-brand-white mb-8">
          Your Cart is Empty
        </h1>
        <p className="text-brand-dark dark:text-brand-white mb-8">
          Looks like you haven't added any items to your cart yet.
        </p>
        <Link
          to="/shop"
          className="inline-block bg-brand-blue text-white px-6 py-3 rounded-lg hover:bg-brand-dark transition-colors"
        >
          Continue Shopping
        </Link>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title="Shopping Cart"
        description="Your shopping cart"
        noindex={true}
      />
      <div className="container mx-auto px-4 py-8 max-w-[80vw]">
        <h1 className="text-3xl font-bold text-brand-dark dark:text-brand-white mb-8">
          Shopping Cart
        </h1>

        <div className="mb-8 p-4 bg-brand-blue/10 rounded-lg">
          {total >= FREE_SHIPPING_THRESHOLD ? (
            <p className="text-sm text-brand-dark dark:text-brand-white flex items-center gap-2">
              <span className="text-brand-blue">✨</span>
              You've qualified for free shipping!
            </p>
          ) : (
            <p className="text-sm text-brand-dark dark:text-brand-white flex items-center gap-2">
              <span className="text-brand-blue">🚚</span>
              Add €{remainingForFreeShipping.toFixed(2)} more to your cart for free shipping!
            </p>
          )}
        </div>

        {hasOneOfKindItem && (
          <div className="mb-8 p-4 bg-brand-blue/10 rounded-lg flex items-start gap-2">
            <FontAwesomeIcon icon={faStar} className="text-brand-blue mt-1" />
            <p className="text-sm text-brand-dark dark:text-brand-white">
              Items marked with a star are one of a kind pieces. Their quantity cannot be modified.
            </p>
          </div>
        )}
        
        <div className="grid gap-6 mb-8">
          {items.map(item => (
            <ProductCard key={item.id} {...item} />
          ))}
        </div>

        <div className="bg-white dark:bg-brand-dark p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-6">
            <span className="text-xl font-semibold text-brand-dark dark:text-brand-white">
              Total
            </span>
            <span className="text-2xl font-bold text-brand-dark dark:text-brand-white">
              €{total.toFixed(2)}
            </span>
          </div>
          
          <div className="flex gap-4">
            <Link
              to="/shop"
              className="flex-1 px-6 py-3 text-center bg-gray-200 dark:bg-brand-charcoal text-brand-dark dark:text-brand-white rounded-lg hover:bg-gray-300 dark:hover:bg-brand-dark transition-colors"
            >
              Continue Shopping
            </Link>
            <button
              onClick={handleCheckout}
              className="flex-1 px-6 py-3 bg-brand-blue text-white rounded-lg hover:bg-brand-dark transition-colors"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;