import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import SEO from '../components/SEO';

const Legal: React.FC = () => {
    const location = useLocation();
    const [isShippingOpen, setShippingOpen] = useState(false);
    const [isReturnsOpen, setReturnsOpen] = useState(false);
    const [isTermsOpen, setTermsOpen] = useState(false);
    const [isPrivacyOpen, setPrivacyOpen] = useState(false);
    const [isIntroOpen, setIntroOpen] = useState(false);

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.getElementById(hash.slice(1));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
                switch (hash) {
                    case '#shipping':
                        setShippingOpen(true);
                        break;
                    case '#returns':
                        setReturnsOpen(true);
                        break;
                    case '#terms':
                        setTermsOpen(true);
                        break;
                    case '#privacy':
                        setPrivacyOpen(true);
                        break;
                }
            }
        }
    }, [location]);

    const toggleSection = (isOpen: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
        setOpen(!isOpen);
    };

    return (
        <>
            <SEO
                title="Legal"
                description="Important legal information about our jewelry store, including terms of service, privacy policy, and shipping information."
                type="website"
            />
            <div className="container mx-auto px-4 py-8 max-w-[80vw]">
                {/* Introductory Text */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="mb-8"
                >
                    <button
                        className="w-full flex justify-between items-center p-4 text-left bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl border border-white/10 hover:bg-white/20 transition-colors"
                        onClick={() => toggleSection(isIntroOpen, setIntroOpen)}
                    >
                        <span className="text-2xl font-semibold text-black dark:text-white">
                            Legal:
                        </span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`text-black dark:text-white transform transition-transform duration-200 ${isIntroOpen ? 'rotate-180' : ''
                                }`}
                        />
                    </button>
                    {isIntroOpen && (
                        <div className="prose prose-lg dark:prose-invert mx-auto mb-12 p-4">
                            <p>
                                This website is operated by Camila Parente. Throughout the site, the terms "we", "us" and "our" refer to Camila Parente. Camila Parente offers this website, including all information, tools and services available from this site to you, the user, is conditioned on your acceptance of all terms, conditions, policies and notices stated herein.
                            </p>
                            <p>
                                By visiting our site and/or purchasing something from us, you are participating in our "Service" and agreeing to the following terms and conditions ("Terms of Service", "Terms"), including all additional terms and conditions and policies to which referenced herein and/or available via hyperlinks. These Terms of Service apply to all users of the site, including but not limited to users who are browsers, vendors, customers, merchants, and/or content contributors.
                            </p>
                            <p>
                                Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you are agreeing to the Terms of Service. If you do not agree to all the terms and conditions of this Agreement, then you should not access the Website or use any of the Services. If the Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                            </p>
                            <p>
                                Any new features or tools added to the current store will also be subject to the Terms of Service. You can review the updated version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of the Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically to verify changes. Your continued use of or access to the Website following the posting of any changes constitutes acceptance of those changes.
                            </p>
                        </div>
                    )}
                </motion.div>
                {/* Shipping & Returns Policy */}
                <motion.div id="shipping"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                    className="mb-8"
                >
                    <button
                        className="w-full flex justify-between items-center p-4 text-left bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl border border-white/10 hover:bg-white/20 transition-colors"
                        onClick={() => toggleSection(isShippingOpen, setShippingOpen)}
                    >
                        <span className="text-2xl font-semibold text-black dark:text-white">
                            Shipping Policy
                        </span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`text-black dark:text-white transform transition-transform duration-200 ${isShippingOpen ? 'rotate-180' : ''
                                }`}
                        />
                    </button>
                    {isShippingOpen && (
                        <div className="prose prose-lg dark:prose-invert mx-auto p-4">
                            <div className="bg-white dark:bg-brand-dark p-8 rounded-lg shadow-md">
                                <h3 className="text-xl font-semibold mb-4">Processing & Shipping Times</h3>
                                <h4 className="font-semibold">Ready-to-Ship Items</h4>
                                <p>Items marked as "In Stock" ship within:</p>
                                <ul>
                                    <li>Express Shipping: 1-2 business days</li>
                                    <li>Standard Shipping: 5-7 business days</li>
                                </ul>
                                <p>Tracking information provided upon shipment.</p>
                                <h4 className="font-semibold">Made-to-Order Items</h4>
                                <p>Each piece is carefully handcrafted after your order. Processing time varies by design complexity. Typical creation time: 2-3 weeks before shipping. We'll communicate specific timeframes during order confirmation.</p>
                                <br />
                                <h3 className="text-xl font-semibold mb-4">Shipping Costs</h3>
                                <p>Orders over €200: Free Shipping within Europe</p>
                                <p>Orders under €200:</p>
                                <ul>
                                    <li>Standard Shipping: €16</li>
                                    <li>Express Shipping: €25</li>
                                </ul>
                                <p>Currently shipping to European countries only. For deliveries outside Europe, please contact us directly via Instagram or email to arrange special shipping options and costs.</p>
                                <br />
                                <h3 className="text-xl font-semibold mb-4">Shipping Methods</h3>
                                <p>All pieces are carefully packaged to ensure safe delivery. Tracking number provided for all shipments. Import duties and taxes (if applicable) are the responsibility of the recipient.</p>

                            </div>
                        </div>
                    )}
                </motion.div>

                <motion.div id="returns"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.8 }}
                    className="mb-8"
                >
                    <button
                        className="w-full flex justify-between items-center p-4 text-left bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl border border-white/10 hover:bg-white/20 transition-colors"
                        onClick={() => toggleSection(isReturnsOpen, setReturnsOpen)}
                    >
                        <span className="text-2xl font-semibold text-black dark:text-white">
                            Returns & Exchanges Policy
                        </span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`text-black dark:text-white transform transition-transform duration-200 ${isReturnsOpen ? 'rotate-180' : ''}`}
                        />
                    </button>
                    {isReturnsOpen && (
                        <div className="prose prose-lg dark:prose-invert mx-auto p-4">
                            <div className="bg-white dark:bg-brand-dark p-8 rounded-lg shadow-md">
                                <h3 className="text-xl font-semibold mb-4">Return Eligibility</h3>
                                <p>Returns accepted within 20 days of delivery. Item must be unworn and in original condition. Original packaging must be included. A return reason must be provided with your return request.</p>

                                <h3 className="text-xl font-semibold mb-4">Return Process</h3>
                                <p>Contact us within 20 days of receiving your item. Provide your order number and reason for return. Once approved, you'll receive return shipping instructions. Return shipping costs are the responsibility of the customer. Refund will be processed within 5 business days of receiving the returned item.</p>

                                <h3 className="text-xl font-semibold mb-4">Damaged Items</h3>
                                <p>If your item arrives damaged, please contact us immediately with photos. Damaged items should be documented within 48 hours of receipt. We will cover return shipping costs for damaged items. A replacement will be crafted or a full refund issued.</p>

                                <h3 className="text-xl font-semibold mb-4">Non-Returnable Items</h3>
                                <p>Custom or personalized pieces, items damaged due to wear or improper handling, items returned without prior authorization, items returned after 20 days.</p>

                                <h3 className="text-xl font-semibold mb-4">Exchange Requests</h3>
                                <p>Exchanges follow the same process as returns. Size adjustments for rings are available (additional crafting fee may apply). For exchanges, shipping costs both ways are customer responsibility.</p>

                                <h3 className="text-xl font-semibold mb-4">Important Notes</h3>
                                <p>All returned items are inspected upon receipt. Refunds are issued to the original payment method. Original shipping costs are non-refundable. We reserve the right to deny returns that don't meet our criteria.</p>
                            </div>
                        </div>
                    )}
                </motion.div>

                {/* Terms of Service Section */}
                <motion.div id="terms"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    className="mb-8"
                >
                    <button
                        className="w-full flex justify-between items-center p-4 text-left bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl border border-white/10 hover:bg-white/20 transition-colors"
                        onClick={() => toggleSection(isTermsOpen, setTermsOpen)}
                    >
                        <span className="text-2xl font-semibold text-black dark:text-white">
                            Terms of Service:
                        </span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`text-black dark:text-white transform transition-transform duration-200 ${isTermsOpen ? 'rotate-180' : ''
                                }`}
                        />
                    </button>
                    {isTermsOpen && (
                        <div className="prose prose-lg dark:prose-invert mx-auto p-4">
                            <div className="bg-white dark:bg-brand-dark p-8 rounded-lg shadow-md">
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 1 - ONLINE STORE TERMS</h2>
                                <p className="mb-6">
                                    By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and that you have given us your consent to allow any of your dependents minors use this site.
                                    You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                                    You must not transmit worms, viruses or any code of a destructive nature.
                                    A breach or violation of any of these Terms will result in immediate termination of your Services.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 2 - GENERAL CONDITIONS</h2>
                                <p className="mb-6">
                                    We reserve the right to refuse service to anyone, for any reason, at any time.
                                    You understand that your content (not including your credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform or adapt to the technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                                    You agree not to reproduce, duplicate, copy, sell, resell or exploit any part of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without the express permission of writing from us.
                                    Titles used in this agreement are included for convenience only and do not limit or affect these Terms.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>
                                <p className="mb-6">
                                    We are not responsible if the information available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without first consulting more accurate, complete, or timely information. Any reliance on the material on this site is at your own risk.
                                    This site may contain certain historical information. Historical information is not necessarily current and is provided solely for your reference. We reserve the right to modify the contents of this site at any time, but we are under no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 4 - CHANGES TO THE SERVICE AND PRICES</h2>
                                <p className="mb-6">
                                    The prices of our products are subject to change without notice.
                                    We reserve the right to modify or discontinue the Service (or any part of the content) at any time without prior notice.
                                    We will not be liable to you or any third party for any modification, price change, suspension or discontinuance of the Service.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 5 - PRODUCTS OR SERVICES</h2>
                                <p className="mb-6">
                                    Certain products or services may be available exclusively online through the Website. These products or services may have limited quantities and may be subject to return or exchange in accordance with our return policy only.
                                    We have made an effort to display the colors and images of our products, in the store, as accurately as possible. We cannot guarantee that your computer monitor will display colors accurately.
                                    We reserve the right, but are not obligated, to limit the sales of our products or services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of products or services that we offer. All product descriptions or product prices are subject to change at any time without notice, at our sole discretion. We reserve the right to discontinue any product at any time. Any product or service offer made on this site is void where prohibited.
                                    We do not warrant that the quality of any products, services, information or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
                                <p className="mb-6">
                                    We reserve the right to refuse any order you place with us. We may, at our discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address.
                                    In the event that we make a change to or cancel an order, we may attempt to notify you by contacting you via email and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our judgment, appear to be placed by dealers, resellers or distributors.
                                    You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you when necessary.
                                    For more details, please review our Return Policy.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 7 - OPTIONAL TOOLS</h2>
                                <p className="mb-6">
                                    We may provide you with access to third-party tools that we do not monitor and over which we have no control or input.
                                    You acknowledge and agree that we provide access to these types of tools "as is" and "as available" without warranties, representations or conditions of any kind and without any endorsement. We will have no liability whatsoever arising from or related to your use of tools provided by third parties.
                                    Any use you make of optional tools offered through the site is at your own risk and discretion and you should ensure that you are familiar with and agree to the terms under which these tools are provided by the third party provider(s).
                                    We may also offer you new services and/or features through the Website in the future (including the release of new tools and resources). These new features and/or services will also be subject to these Terms of Service.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 8 - THIRD PARTY LINKS</h2>
                                <p className="mb-6">
                                    Certain content, products and services available via our Service may include material from third parties.
                                    Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third party materials or websites, or for any third party materials, products or services.
                                    We are not responsible for any damages or damages related to the acquisition or use of goods, services, resources, content, or any other transaction made in connection with third-party websites. Please review the third party's policies and practices carefully and make sure you understand them before engaging in any transaction. Complaints, claims, concerns or questions regarding third party products should be directed to the third party.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
                                <p className="mb-6">
                                    If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate, or otherwise use in any medium any comments you submit to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for comments; or (3) to respond to comments.
                                    We may, but have no obligation to, monitor, edit, or remove content that we deem to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene, or objectionable, or violates any party's intellectual property or the Terms of Service.
                                    You agree that your comments will not violate the rights of any third party, including copyright, trademark, privacy, personality or other personal or proprietary rights. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false email address, use another identity that is not legitimate, or mislead us or third parties as to the origin of your comments. You are solely responsible for the comments you make and their accuracy. We are not responsible and assume no liability with respect to comments posted by you or any third party.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 10 - PERSONAL INFORMATION</h2>
                                <p className="mb-6">
                                    Your submission of personal information through the site is governed by our Privacy Policy. To see our Privacy Policy.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h2>
                                <p className="mb-6">
                                    From time to time there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit time and the availability. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information or cancel orders if any information on the Service or on any related website is inaccurate at any time without notice (including after you have submitted your order).
                                    We do not undertake any obligation to update, correct or clarify the information on the Service or on any related website, including, without limitation, pricing information, except as required by law. No updated specification or update date applied in the Service or on any related website should be taken to indicate that all information in the Service or on any related website has been changed or updated.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 12 - PROHIBITED USES</h2>
                                <p className="mb-6">
                                    In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to ask others to perform or participate in unlawful acts; (c) to violate any international, federal, provincia or state regulations, rules, laws, or local ordinances; (d) to infringe or violate our or third party intellectual property rights; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin or disability; (f) to present false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that could affect the functionality or operation of the Service or any related website, other sites or the Internet; (h) to collect or track personal information about others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the items of prohibited uses.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 13 - EXCLUSION OF WARRANTIES; LIMITATION OF LIABILITY</h2>
                                <p className="mb-6">
                                    We do not warrant or warrant that your use of our service will be uninterrupted, timely, secure, or error-free.
                                    We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                                    You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time without notice.
                                    You expressly agree that your use of, or ability to use, the Service is at your own risk. The Service and all products and services provided through the Service are (except as expressly stated by us) provided "as is" and "as available" for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                                    In no event shall camila parente, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any direct, indirect, incidental, punitive, special or consequences of any kind, including, without limitation, loss of profits, loss of income, loss of savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability, or otherwise, as a result of the use of any of the services or products purchased through the service, or for any other claim related in any way to the use of the service or any product, including but not limited to any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content. do (or product) published, transmitted, or made available through the service, even if advised of its possibility. Because some states or jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the fullest extent permitted by law.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 14 - INDEMNIFICATION</h2>
                                <p className="mb-6">
                                    You agree to indemnify, defend and hold harmless Camila Parente and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable fees of lawyers, made by any third party due to or as a result of your breach of the Terms of Service or the documents they incorporate by reference, or the violation of any law or the rights of a third party.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 15 - SEVERABILITY</h2>
                                <p className="mb-6">
                                    In the event any provision of these Terms of Service is found to be unlawful, void, or unenforceable, such provision shall nonetheless be effective to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed severed from these Terms of Service, such determination will not affect the validity of applicability of the other remaining provisions.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 16 - TERMINATION</h2>
                                <p className="mb-6">
                                    The obligations and liabilities of the parties incurred prior to the termination date will survive the termination of this agreement for all purposes.
                                    These Terms of Service are effective unless and until terminated by you or us. You can terminate these Terms of Service at any time by notifying us that you no longer wish to use our services, or when you stop using our site.
                                    If, in our judgment, you fail, or are suspected of failing, to comply with any term or provision of these Terms of Service, we may also terminate this agreement at any time without notice, and you will remain responsible for all amounts due until including the termination date; and/or accordingly we may deny you access to our services (or any part thereof).
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 17 - ENTIRE AGREEMENT</h2>
                                <p className="mb-6">
                                    Our failure to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                                    These Terms of Service and any policies or operating rules posted by us on this site or in connection with the Service constitute the entire agreement and understanding between you and us and govern your use of the Service and supersede any prior or contemporaneous agreements, communications and proposals., whether oral or written, between you and us (including, but not limited to, any prior version of the Terms of Service).
                                    Any ambiguity in the interpretation of these Terms of Service shall not be construed against the writing group.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 18 - LAW</h2>
                                <p className="mb-6">
                                    These Terms of Service and any separate agreements in which we provide services to you shall be governed by and construed in accordance with the laws of Juarez 182, Mexico City, DIF, 14000, Mexico.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 19 - CHANGES TO THE TERMS OF SERVICE</h2>
                                <p className="mb-6">
                                    You can review the most current version of the Terms of Service at any time on this page.
                                    We reserve the right, at our sole discretion, to update, modify or replace any part of these Terms of Service by posting updates and changes on our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our Website or the Service following the posting of any changes to these Terms of Service constitutes your acceptance of those changes.
                                </p>
                                <h2 className="text-2xl font-semibold mt-8 mb-4">SECTION 20 - CONTACT INFORMATION</h2>
                                <p className="mb-6">
                                    Questions about the Terms of Service should be sent to camila.mpp88@gmail.com.
                                </p>
                            </div>
                        </div>
                    )}
                </motion.div>

                {/* Privacy Policy Section */}
                <motion.div id="privacy"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="mb-8"
                >
                    <button
                        className="w-full flex justify-between items-center p-4 text-left bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl border border-white/10 hover:bg-white/20 transition-colors"
                        onClick={() => toggleSection(isPrivacyOpen, setPrivacyOpen)}
                    >
                        <span className="text-2xl font-semibold text-black dark:text-white">
                            Privacy Policy:
                        </span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`text-black dark:text-white transform transition-transform duration-200 ${isPrivacyOpen ? 'rotate-180' : ''
                                }`}
                        />
                    </button>
                    {isPrivacyOpen && (
                        <div className="prose prose-lg dark:prose-invert mx-auto p-4">
                            <div className="bg-white dark:bg-brand-dark p-8 rounded-lg shadow-md">
                                <h2 className="text-2xl font-semibold mb-4">SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h2>
                                <p className="mb-6">
                                    When you buy something from our store, as part of the buying and selling process, we collect the personal information that we receive from you.
                                    data such as name, address and email.
                                    When you browse our store, we also automatically receive your computer's Internet Protocol (IP) address.
                                    in order to provide us with information that helps us learn about your browser and operating system.
                                    Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.
                                </p>
                                <h2 className="text-2xl font-semibold mb-4">SECTION 2 - CONSENT</h2>
                                <p className="mb-6">
                                    How do you get my consent?
                                    When you provide us with your personal information to complete a transaction, verify your credit card, create an order, arrange a shipment or make a return, we imply that you consent to its collection and use for that specific reason only.
                                    If we ask for your personal information for a secondary reason, such as marketing, we will ask you directly for your express consent, or we will give you the opportunity to refuse.
                                    How can I withdraw my consent?
                                    If after having accepted you change your mind, you can revoke your consent for us to contact you, for the collection, use or disclosure of your information, at any time, by contacting us at camila.mpp88@gmail.com or by writing to: Camila Parente Juarez 182 , Mexico City, 14000, Mexico
                                </p>
                                <h2 className="text-2xl font-semibold mb-4">SECTION 3 - DISCLOSURE</h2>
                                <p className="mb-6">
                                    If you choose a direct payment gateway to complete your purchase, then Stripe stores your credit card data. It is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only to the extent necessary to complete the purchase transaction. After it is complete, your purchase transaction information is deleted.
                                    All direct payment gateways adhere to the standards set forth by PCI-DSS as outlined by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express, and Discover.
                                    PCI-DSS requirements help ensure the secure handling of credit card information by stores and their service providers.
                                    For more insight, you might also want to read Stripe's Terms of Service here or PrivacyStatement here.
                                </p>
                                <h2 className="text-2xl font-semibold mb-4">SECTION 5 - THIRD PARTY SERVICES</h2>
                                <p className="mb-6">
                                    In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                                    However, some third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies with respect to the information we are required to provide to them for purchase-related transactions.
                                    For these providers, we recommend that you read their privacy policies so that you can understand how your personal information will be handled.
                                    In particular, remember that some providers may be located or have facilities that are in a different jurisdiction than you or us. So if you wish to proceed with a transaction that involves the services of a third-party provider, your information may be subject to the laws of the jurisdiction (jurisdictions) in which the service provider or its facilities are located.
                                    By way of example, if you are located in Canada and your transaction is processed by a United States-based payment gateway, then your personal information used to complete the transaction may be subject to disclosure under United States law, including the Patriot Act.
                                    Once you leave our store website or are redirected to a third party site or application, you are no longer governed by this Privacy Policy or our website Terms of Service. links
                                    When you click on links on our store, you may be directed away from our site. We are not responsible for the privacy practices of other sites and we encourage you to read their privacy policy.
                                </p>
                                <h2 className="text-2xl font-semibold mb-4">SECTION 6 - SAFETY</h2>
                                <p className="mb-6">
                                    To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
                                    If you provide us with your credit card information, it is encrypted using Secure Socket Layer (SSL) technology and stored with AES-256 encryption. Although no method of transmission over the Internet or method of electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional industry-accepted standards.
                                </p>
                                <p>
                                    Here is a list of cookies that we use. We list them so you can choose whether or not to opt out:
                                    _session_id, unique token, sessional, Allows us to store information about your session (referrer, landing page, etc).
                                    cart, unique token, persistent for 2 weeks, Stores information about the contents of your cart.
                                    _secure_session_id, unique token, sessional
                                </p>
                                <h2 className="text-2xl font-semibold mb-4">SECTION 7 - AGE OF CONSENT</h2>
                                <p className="mb-6">
                                    By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and that you have given us your consent to allow any of your dependents minors use this site.
                                </p>
                                <h2 className="text-2xl font-semibold mb-4">SECTION 8 - CHANGES TO THIS PRIVACY POLICY</h2>
                                <p className="mb-6">
                                    We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately after their publication on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                                    If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                                </p>
                            </div>
                        </div>
                    )}
                </motion.div>

                {/* Questions & Contact Information */}
                <motion.div id="info"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.8 }}
                    className="mb-8"
                >
                    <section className='mt-20'>
                        <h1 className="text-4xl md:text-5xl font-bold text-brand-dark dark:text-brand-white mb-12 text-center">
                            Questions & Contact Information
                        </h1>
                        <div className="prose prose-lg dark:prose-invert mx-auto">
                            <div className="bg-white dark:bg-brand-dark p-8 rounded-lg shadow-md">
                                <p className="mb-6">
                                    If you want to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at camila.mpp88@gmail.com or by postal mail to:
                                </p>
                                <p className="mb-6">
                                    Camila Parente<br />
                                    Re: Privacy Compliance Officer<br />
                                    Juarez 182, Mexico City, 14000, Mexico
                                </p>
                            </div>
                        </div>
                    </section>
                </motion.div>

             
            </div>
        </>
    );
};

export default Legal;