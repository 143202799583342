import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import SEO from '../components/SEO';

const Contact: React.FC = () => {
  const [customRequest, setCustomRequest] = useState({
    name: '',
    email: '',
    message: '',
    reference: 'Contact Form Request' // Default reference
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const response = await fetch('/api/custom-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customRequest)
      });

      if (!response.ok) {
        throw new Error('Failed to submit request');
      }

      setSubmitSuccess(true);
      setCustomRequest({
        name: '',
        email: '',
        message: '',
        reference: 'Contact Form Request'
      });
      
      // Reset success message after 3 seconds
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 3000);
    } catch (error) {
      setSubmitError('Failed to submit request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SEO 
        title="Custom Jewelry Request"
        description="Request a custom piece of jewelry. Share your vision and let's create something unique together."
      />
      <div className="relative w-screen min-h-screen">
        {/* Background with gradient overlay */}
        <div className="absolute inset-0 bg-brand-blue bg-opacity-50 dark:bg-opacity-100">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -top-20 -left-20 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
            <div className="absolute top-40 right-20 w-80 h-80 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
            <div className="absolute bottom-20 left-1/3 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          </div>
        </div>

        {/* Content */}
        <div className="relative py-20 px-4">
          <div className="max-w-6xl mx-auto">
            {/* Hero Section */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center mb-16"
            >
              <h1 className="text-4xl md:text-5xl font-bold text-black dark:text-white mb-6">
                Custom Jewelry Request
              </h1>
              <p className="text-xl text-black/80 dark:text-white/80 max-w-2xl mx-auto">
                Let's create something unique together. Share your vision and I'll help bring it to life through a custom piece of jewelry.
              </p>
            </motion.div>

            {/* Contact Cards */}
            <div className="grid md:grid-cols-2 gap-8">
              {/* Custom Request Form */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10 p-8"
              >
                <h2 className="text-2xl font-semibold text-black dark:text-white mb-6">
                  Request Your Custom Piece
                </h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <input
                    type="text"
                    placeholder="Your Name"
                    value={customRequest.name}
                    onChange={(e) => setCustomRequest({ ...customRequest, name: e.target.value })}
                    className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={customRequest.email}
                    onChange={(e) => setCustomRequest({ ...customRequest, email: e.target.value })}
                    className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                    required
                  />
                  <textarea
                    placeholder="Tell us about your vision..."
                    value={customRequest.message}
                    onChange={(e) => setCustomRequest({ ...customRequest, message: e.target.value })}
                    className="w-full p-2 border rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                    rows={4}
                    required
                  />
                  <button
                    type="submit"
                    className="w-full bg-brand-blue text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                    ) : 'Send Request'}
                  </button>
                  
                  {submitSuccess && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-green-500 text-center"
                    >
                      Successfully sent your request! We'll be in touch soon.
                    </motion.div>
                  )}
                  
                  {submitError && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-red-500 text-center"
                    >
                      {submitError}
                    </motion.div>
                  )}
                </form>
              </motion.div>

              {/* Instagram Feed */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10 p-8"
              >
                <h2 className="text-2xl font-semibold text-black dark:text-white mb-6">
                  Get Inspired
                </h2>
                <p className="text-black/80 dark:text-white/80 mb-8">
                  Browse through my latest creations and custom pieces on Instagram:
                </p>
                <div className="aspect-square rounded-xl overflow-hidden bg-black/20">
                  <iframe 
                    src="https://www.instagram.com/camilamartinezparente/embed"
                    className="w-full h-full"
                    frameBorder="0"
                    scrolling="no"
                    allow="transparency"
                  ></iframe>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;