import React, { createContext, useContext } from 'react';
import { stockData } from '../data/stock';
import { Product, ProductVariant } from '../types/product-variant';

interface StockContextType {
  getStock: (productId: string, variantId?: string) => number;
}

const StockContext = createContext<StockContextType | null>(null);

export const useStock = () => {
  const context = useContext(StockContext);
  if (!context) {
    throw new Error('useStock must be used within a StockProvider');
  }

  const getStock = (productId: string, variantId?: string): number => {
    // If variant is specified, return variant stock directly from stockData
    if (variantId) {
      return stockData[variantId] || 0;
    }

    // If no variant specified, return stock for the main product
    return stockData[productId] || 0;
  };

  return { getStock };
};

export const StockProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const getStock = (productId: string, variantId?: string): number => {
    if (variantId) {
      return stockData[variantId] || 0;
    }
    return stockData[productId] || 0;
  };

  return (
    <StockContext.Provider value={{ getStock }}>
      {children}
    </StockContext.Provider>
  );
};

export default StockContext; 