import { loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<any> | null = null;

const getStripe = () => {
  if (!stripePromise) {
    const key = window.location.hostname.includes('vercel.app')
      ? import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_TEST
      : import.meta.env.STRIPE_PUBLISHABLE_KEY;

    if (!key) {
      throw new Error('Stripe publishable key is missing');
    }

    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

export default getStripe; 