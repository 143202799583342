import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const ShippingInfo = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mt-8">
            <button
                className="w-full flex justify-between items-center p-4 text-left bg-white/5 dark:bg-brand-charcoal/30 backdrop-blur-md rounded-lg border border-white/10 hover:bg-white/10 dark:hover:bg-brand-charcoal/40 transition-all duration-200"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-lg font-semibold text-black dark:text-white">
                    Shipping Information
                </span>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`text-black dark:text-white transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
                />
            </button>
            
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -10, height: 0 }}
                        animate={{ opacity: 1, y: 0, height: "auto" }}
                        exit={{ opacity: 0, y: -10, height: 0 }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                        className="overflow-hidden"
                    >
                        <div className="mt-4 p-6 bg-white/5 dark:bg-brand-charcoal/30 backdrop-blur-md rounded-lg border border-white/10">
                            <div className="space-y-4">
                                <div>
                                    <h4 className="text-base font-semibold text-black dark:text-white mb-2">
                                        Ready-to-Ship Items
                                    </h4>
                                    <p className="text-sm text-gray-700 dark:text-gray-300">
                                        Items marked as "In Stock" ship within:
                                    </p>
                                    <ul className="mt-2 space-y-1 text-sm text-gray-700 dark:text-gray-300">
                                        <li className="flex items-center gap-2">
                                            • Express Shipping: 1-2 business days
                                        </li>
                                        <li className="flex items-center gap-2">
                                            • Standard Shipping: 5-7 business days
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <h4 className="text-base font-semibold text-black dark:text-white mb-2">
                                        Shipping Costs
                                    </h4>
                                    <p className="text-sm text-gray-700 dark:text-gray-300">
                                        Orders over €200: Free Shipping within Europe
                                    </p>
                                    <p className="text-sm text-gray-700 dark:text-gray-300 mt-1">
                                        Orders under €200:
                                    </p>
                                    <ul className="mt-2 space-y-1 text-sm text-gray-700 dark:text-gray-300">
                                        <li className="flex items-center gap-2">
                                            • Standard Shipping: €16
                                        </li>
                                        <li className="flex items-center gap-2">
                                            • Express Shipping: €25
                                        </li>
                                    </ul>
                                </div>
                                
                                <p className="text-sm text-gray-700 dark:text-gray-300 mt-4">
                                    Currently shipping to European countries only. For deliveries outside Europe, 
                                    please contact us directly via Instagram or email to arrange special shipping options and costs.
                                </p>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ShippingInfo;