import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookieConsent } from '../context/CookieConsentContext';

interface FacebookPixelFunction {
  (...args: any[]): void;
  callMethod?: (...args: any[]) => void;
  queue?: any[];
  push?: (...args: any[]) => void;
  loaded?: boolean;
  version?: string;
}

declare global {
  interface Window {
    fbq?: FacebookPixelFunction;
    _fbq?: FacebookPixelFunction;
  }
}

const PIXEL_ID = import.meta.env.VITE_FB_PIXEL_ID;

// Initialize Facebook Pixel only when marketing consent is given
const initializeFBPixel = () => {
  if (!PIXEL_ID) return;

  // Properly typed Facebook Pixel initialization
  (function(f: Window, b: Document, e: string, v: string, n: any, t: any, s: any) {
    if (typeof f.fbq === 'function') return;
    n = f.fbq = function() {
      n.callMethod ? 
        n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    } as FacebookPixelFunction;
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    if (s?.parentNode) {
      s.parentNode.insertBefore(t, s);
    }
  }(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
    undefined,
    undefined,
    undefined
  ));
  
  window.fbq?.('init', PIXEL_ID);
};

// Utility functions for tracking events
export const trackAddToCart = (data: {
  content_name: string;
  content_ids: string[];
  value: number;
  currency: string;
}) => {
  const { consent } = useCookieConsent();
  if (typeof window.fbq === 'function' && consent.marketing) {
    window.fbq('track', 'AddToCart', data);
  }
};

export const trackInitiateCheckout = (data: {
  content_ids: string[];
  contents: Array<{ id: string; quantity: number }>;
  value: number;
  currency: string;
}) => {
  const { consent } = useCookieConsent();
  if (typeof window.fbq === 'function' && consent.marketing) {
    window.fbq('track', 'InitiateCheckout', data);
  }
};

export const trackPurchase = (data: {
  content_ids: string[];
  contents: Array<{ id: string; quantity: number }>;
  value: number;
  currency: string;
}) => {
  const { consent } = useCookieConsent();
  if (typeof window.fbq === 'function' && consent.marketing) {
    window.fbq('track', 'Purchase', data);
  }
};

const MetaPixel: React.FC = () => {
  const { consent } = useCookieConsent();
  const location = useLocation();

  useEffect(() => {
    // Only initialize pixel if marketing consent is given
    if (consent.marketing) {
      initializeFBPixel();
    }
  }, [consent.marketing]);

  useEffect(() => {
    // Only track pageviews if marketing consent is given
    if (consent.marketing && typeof window.fbq === 'function') {
      window.fbq('track', 'PageView');
    }
  }, [location, consent.marketing]);

  return null;
};

export default MetaPixel;
