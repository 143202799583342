// src/components/CartDropdown.tsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../context/CartContext';
import ProductCard from './ProductCard';
import { calculateTotal } from '../functions/calculateTotal';
import getStripe from '../utils/stripe-config';
import { useStock } from '../context/StockContext';

const CartDropdown: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { items, email, updateQuantity } = useCart();
  const { getStock } = useStock();
  const [stockError, setStockError] = useState<string | null>(null);
  const total = calculateTotal(items);
  const hasOneOfKindItem = items.some(item => item.oneOfKind);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCheckout = async () => {
    try {
      const stripe = await getStripe();
      
      if (!stripe) {
        throw new Error('Failed to initialize Stripe');
      }

      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          items: items.map(item => ({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            oneOfKind: item.oneOfKind,
            variantId: item.variantId || null,
            slug: item.slug,
            image: `/products/${item.image.split('/products/')[1] || item.image}`
          }))
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const { sessionId } = await response.json();
      
      if (!sessionId) {
        throw new Error('No session ID returned from server');
      }

      onClose();
      
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (error) {
      console.error('Checkout error:', error);
      alert('Failed to initialize checkout. Please try again.');
    }
  };

  const handleQuantityUpdate = (id: string, newQuantity: number) => {
    const availableStock = getStock(id);
    if (newQuantity > availableStock) {
      setStockError(`Only ${availableStock} items available in stock`);
      setTimeout(() => setStockError(null), 3000);
      return;
    }
    // Update quantity if within stock limits
    updateQuantity(id, newQuantity);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: isMobile ? '100%' : -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: isMobile ? '100%' : -20 }}
          className={`
            ${isMobile ? 'fixed inset-0 z-[90] bg-white dark:bg-black' : 
            'absolute top-full right-0 mt-2 w-96 bg-white dark:bg-black rounded-lg shadow-xl z-[90]'}
          `}
        >
          <div className={`cart-dropdown ${isMobile ? 'h-full flex flex-col' : 'p-4'}`}>
            <div className={`
              flex justify-between items-center
              ${isMobile ? 'p-4 border-b border-gray-200 dark:border-brand-charcoal' : 'mb-4'}
            `}>
              <h2 className="text-xl font-semibold text-black dark:text-white">Your Cart</h2>
              <button onClick={onClose} className="text-black dark:text-white">×</button>
            </div>
            
            {hasOneOfKindItem && (
              <div className="mb-4 p-3 bg-brand-blue/10 rounded-lg flex items-start gap-2">
                <FontAwesomeIcon icon={faStar} className="text-brand-blue mt-1" />
                <p className="text-sm text-black dark:text-white">
                  Items marked with a star are one of a kind pieces. Their quantity cannot be modified.
                </p>
              </div>
            )}

            {stockError && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="mb-4 p-3 bg-red-500/10 text-red-500 rounded-lg"
              >
                {stockError}
              </motion.div>
            )}

            <div className={`
              space-y-4 overflow-y-auto
              ${isMobile ? 'flex-1 p-4' : 'max-h-96'}
            `}>
              {items.length === 0 ? (
                <p className="text-center text-black dark:text-white">Your cart is empty</p>
              ) : (
                items.map(item => (
                  <ProductCard 
                    key={item.id} 
                    {...item} 
                    onQuantityChange={(quantity) => handleQuantityUpdate(item.id, quantity)}
                  />
                ))
              )}
            </div>

            {items.length > 0 && (
              <div className={`
                border-t border-gray-200 dark:border-brand-charcoal
                ${isMobile ? 'p-4 mt-auto' : 'mt-4 pt-4'}
              `}>
                <p className="text-lg font-semibold text-black dark:text-white mb-4">
                  Total: €{total.toFixed(2)}
                </p>
                <div className="space-y-2">
                  <Link
                    to="/cart"
                    className="block w-full text-center bg-gray-200 dark:bg-brand-charcoal text-brand-dark dark:text-brand-white py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-brand-dark transition-colors"
                    onClick={onClose}
                  >
                    View Cart
                  </Link>
                  <button
                    onClick={handleCheckout}
                    className="block w-full text-center bg-brand-blue text-white py-2 rounded-lg hover:bg-brand-dark transition-colors"
                  >
                    Checkout
                  </button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CartDropdown;