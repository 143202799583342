interface ProcessMedia {
  type: 'video' | 'image';
  src: string;
  description: string;
}

interface CategoryContent {
  title: string;
  description: string;
  detailedDescription: string;
  seoDescription: string;
  categoryFilter: string[];
  heroImage: string;
  additionalHeroImages: string[];
  processMedia: ProcessMedia[];
  processText: string[];
}

export const categoryContent: Record<string, CategoryContent> = {
  'earrings-and-hoops': {
    title: "Earrings and Hoops",
    description: "Where contemporary design meets timeless elegance",
    detailedDescription:
      "Step into a world where each earring is a miniature masterpiece in motion. Our earrings aren't just accessories – they're carefully balanced sculptures that dance with your every movement, framing your face in ways that celebrate your unique features.\n\n" +
      "Each piece is thoughtfully crafted to enhance your natural beauty. Whether you're drawn to our bold architectural hoops that catch the light as they swing, or our character-inspired pendants that tell stories as they move, you'll find pieces that don't just decorate – they transform.\n\n" +
      "From the fluid forms of our Lava collection to the mythological creatures in our Poldanceras series, every earring is designed with your face as the canvas. We consider how each curve and angle will complement your features, how light will play across the surfaces, and how movement will bring each piece to life.",
    categoryFilter: ["Earrings", "Hoops", "Earring (Singular)", "Hoop (Singular)"],
    heroImage: "/buhos-20modelo.jpg",
    additionalHeroImages: [],
    processMedia: [
      {
        type: 'video',
        src: '/video/dancer-dorch.webm',
        description: 'Each piece is carefully crafted to achieve the perfect balance'
      },
      {
        type: 'video',
        src: '/video/fusion-test.webm',
        description: 'Traditional silversmithing techniques in action'
      }
    ],
    processText: [
      "Our **design philosophy** focuses on creating pieces that move gracefully with you.",
      "Each earring undergoes **extensive testing** to ensure perfect weight distribution and comfort.",
      "We use **traditional techniques** combined with modern design principles for lasting quality.",
      "**Final quality checks** ensure each piece meets our high standards for both beauty and wearability."
    ],
    seoDescription: "Handmade sterling silver earrings & statement hoops | Designer jewelry featuring architectural designs, dangle earrings, and unique hoop earrings. Lightweight, comfortable, and perfect for everyday wear or special occasions. Artisanal crafted jewelry that combines modern design with timeless elegance."
  },
  'rings': {
    title: "Rings",
    description: "Where Stories Take Shape",
    detailedDescription:
      "Our rings represent the perfect marriage of sculptural artistry and wearable design. Each piece challenges us to capture entire worlds within the perfect circle of a ring – and the results are extraordinary.\n\n" +
      "Unbounded by the weight constraints of earrings, these pieces become miniature stages where stories unfold. From the ancient-inspired Ruinas collection to the contemporary boldness of our Lava series, each ring is an opportunity to wear a piece of art that speaks to your personality.\n\n" +
      "We carefully select and incorporate precious stones and unique materials that complement each design, creating pieces that are both striking sculptures and intimate treasures. Whether you choose a bold statement piece or a delicate daily companion, you're wearing a unique story on your finger.",
    categoryFilter: ["Rings"],
    heroImage: "/Lava-ring-style.jpg",
    additionalHeroImages: [
      "/Lava-ring-plant.jpg",
    ],
    processMedia: [
      {
        type: 'video',
        src: '/Ring-workshop.webm',
        description: "Each ring starts as a unique design inspired by organic forms"
      },
      {
        type: 'video',
        src: '/video/fusion-test.webm',
        description: "Meticulous attention to detail in every curve and texture"
      }
    ],
    processText: [
      "Our **design process** begins with extensive sketching and material exploration.",
      "Each ring undergoes **precise crafting** to ensure comfort and durability.",
      "We incorporate **unique textures** and finishes that make each piece distinctive.",
      "**Final polishing** brings out the inherent beauty of each material used."
    ],
    seoDescription: "Luxury handcrafted silver rings | Contemporary designer rings featuring sculptural designs and precious stones. Statement rings, everyday rings, and artisanal jewelry pieces made with traditional silversmithing. Each ring tells a unique story through modern artistic expression."
  },
  'unique-finds': {
    title: "Unique Finds",
    description: "One-of-a-kind pieces for the extraordinary",
    detailedDescription: 
      "Welcome to our most experimental and groundbreaking collection. These pieces represent the frontier of jewelry design, where traditional boundaries dissolve and new possibilities emerge.\n\n" +
      "Using innovative techniques like our signature magnetic closures, we've created pieces that protect your clothing while making bold statements. Our collection includes everything from detailed brass roosters to delicate coral formations, each designed to transform ordinary garments into extraordinary canvases.\n\n" +
      "These pieces are for the adventurous spirit who seeks something truly different. They're conversation starters, art pieces, and fashion statements all in one – perfect for those who dare to wear their creativity on their sleeve (quite literally!).",
    categoryFilter: ["Pendant", "Bracelet", "Necklace", "Collar Accessories"],
    heroImage: "/products/Granchi/GRANCHI-PUL-PLA-001-MAIN.jpg",
    additionalHeroImages: [
    ],
    processMedia: [
      {
        type: 'video',
        src: '/video/wax-bts.webm',
        description: "Experimental techniques lead to unexpected beauty"
      },
      {
        type: 'video',
        src: '/video/fusion-test.webm',
        description: "Each piece tells its own unique story"
      }
    ],
    processText: [
      "Our **innovative design process** starts with a deep exploration of unique forms and textures.",
      "Each piece undergoes **meticulous crafting** using both traditional and contemporary techniques.",
      "We focus on creating pieces that are not just beautiful, but also **functional and comfortable**.",
      "**Quality control** is paramount - every piece is thoroughly inspected before reaching you."
    ],
    seoDescription: "Exclusive designer jewelry collection | One-of-a-kind pendants, bracelets, and innovative magnetic jewelry pieces. Handmade artistic accessories featuring brass and silver designs. Limited edition statement pieces for the fashion-forward collector."
  }
};
