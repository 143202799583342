import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Shop from './pages/shop';
import Cart from './pages/cart';
import Header from './components/Header';
import Layout from './layouts/layout';
import Footer from './components/Footer';
import ProductPage from './pages/product';
import Legal from './pages/legal';
import ScrollToTop from './functions/ScrollToTop';
import { CartProvider } from './context/CartContext';
import { HelmetProvider } from 'react-helmet-async';
import CategoryPageWrapper from './pages/categories/[category]';
import { SpeedInsights } from '@vercel/speed-insights/react';
import Success from './pages/success';
import { UIProvider } from './context/UIContext';
import MetaPixel from './components/MetaPixel';
import NotFound from './pages/NotFound';
import ProductFeed from './pages/ProductFeed';
import { StockProvider } from './context/StockContext';
import { CookieConsentProvider } from './context/CookieConsentContext';
import CookieBanner from './components/CookieBanner';
import AnalyticsWrapper from './components/AnalyticsWrapper';
import { useCookieConsent } from './context/CookieConsentContext';

const App: React.FC = () => {
  return (
    <Router>
      <CookieConsentProvider>
        <HelmetProvider>
          <StockProvider>
            <CartProvider>
              <UIProvider>
                <div className="flex flex-col min-h-screen overflow-x-hidden">
                  <MetaPixel />
                  <ScrollToTop />
                  <Header />
                  <Layout>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/shop" element={<Shop />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route path="/product/:slug" element={<ProductPage />} />
                      <Route path="/legal" element={<Legal />} />
                      <Route path="/categories/:category" element={<CategoryPageWrapper />} />
                      <Route path="/success" element={<Success />} />
                      <Route path="/product-feed" element={<ProductFeed />} />
                      <Route path="/google-feed" element={<ProductFeed />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Layout>
                  <Footer />
                  <AnalyticsWrapper />
                  <CookieBanner />
                </div>
              </UIProvider>
            </CartProvider>
          </StockProvider>
        </HelmetProvider>
      </CookieConsentProvider>
    </Router>
  );
};

export default App;