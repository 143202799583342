import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import type { Product } from '../../types/product-variant';

export const SIMILARITY_SCORES = {
    SAME_MATERIAL: 3,
    SAME_CATEGORY: 2,
    SAME_COLLECTION: 2,
};

export const getRelatedProducts = (
    currentProduct: Product,
    allProducts: Product[],
    cartItems: { id: string }[],
    maxProducts: number = 12
): Product[] => {
    const cartItemIds = cartItems.map(item => item.id);
    const availableProducts = allProducts.filter(p => 
        p.id !== currentProduct.id && !cartItemIds.includes(p.id)
    );

    const productsWithScores = availableProducts.map(product => {
        let score = 0;
        
        if (product.material === currentProduct.material) {
            score += SIMILARITY_SCORES.SAME_MATERIAL;
        }
        
        if (product.category === currentProduct.category) {
            score += SIMILARITY_SCORES.SAME_CATEGORY;
        }
        
        if (product.collection === currentProduct.collection) {
            score += SIMILARITY_SCORES.SAME_COLLECTION;
        }

        return { product, score };
    });

    return productsWithScores
        .sort((a, b) => b.score - a.score)
        .slice(0, maxProducts)
        .map(item => item.product);
};

interface RelatedProductsProps {
    products: Product[];
}

const RelatedProducts = ({ products }: RelatedProductsProps) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isReady, setIsReady] = useState(false);
    const itemWidth = isMobile ? 160 : 280;
    const itemMargin = 16;
    const itemsToShow = isMobile ? 2 : 4;
    const [scrollPosition, setScrollPosition] = useState(0);

    const totalWidth = (itemWidth + itemMargin) * products.length;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Initial setup effect
    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.scrollLeft = 0;
            setScrollPosition(0);
            
            // Add a small delay to ensure DOM is fully rendered
            setTimeout(() => {
                setIsReady(true);
            }, 100);
        }
    }, [products]);

    const scrollCarousel = (direction: 'left' | 'right', itemsToScroll: number = 2) => {
        if (carouselRef.current) {
            const scrollAmount = direction === 'left' ? -itemWidth * itemsToScroll : itemWidth * itemsToScroll;
            carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            setScrollPosition(prev => prev + scrollAmount);
        }
    };

    // Calculate arrow visibility
    const showLeftArrow = scrollPosition > 0;
    const showRightArrow = carouselRef.current && isReady ? 
        totalWidth - carouselRef.current.clientWidth > scrollPosition : 
        false;

    return (
        <div className="mt-8 md:mt-20 mb-4 md:mb-8">
            <h2 className="text-xl md:text-2xl font-semibold text-black dark:text-white mb-4 md:mb-6 px-4 md:px-0">
                You Might Also Like:
            </h2>
            
            <div className="relative w-screen left-1/2 right-1/2 -mx-[50vw] ml-[calc(-50vw+0px)] mr-[calc(-50vw+0px)]">
                <div className="relative w-full mx-auto px-4">
                    {isReady && (
                        <>
                            {showLeftArrow && (
                                <button
                                    onClick={() => scrollCarousel('left', itemsToShow)}
                                    className="absolute left-4 md:left-8 top-1/2 -translate-y-1/2 z-20 bg-white/80 dark:bg-brand-dark/80 backdrop-blur-sm text-brand-dark dark:text-white p-2 rounded-full shadow-lg hover:bg-white dark:hover:bg-brand-dark transition-colors"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />
                                </button>
                            )}
                            {showRightArrow && (
                                <button
                                    onClick={() => scrollCarousel('right', itemsToShow)}
                                    className="absolute right-4 md:right-8 top-1/2 -translate-y-1/2 z-20 bg-white/80 dark:bg-brand-dark/80 backdrop-blur-sm text-brand-dark dark:text-white p-2 rounded-full shadow-lg hover:bg-white dark:hover:bg-brand-dark transition-colors"
                                >
                                    <FontAwesomeIcon icon={faArrowRight} className="h-4 w-4" />
                                </button>
                            )}
                        </>
                    )}

                    <div className="relative overflow-hidden">
                        {/* Left Feather Overlay */}
                        {showLeftArrow && (
                            <div className="absolute left-0 top-0 bottom-0 w-24 bg-gradient-to-r from-brand-sand dark:from-brand-dark via-brand-sand/80 dark:via-brand-dark/80 to-transparent z-10 pointer-events-none" />
                        )}
                        {/* Right Feather Overlay */}
                        {showRightArrow && (
                            <div className="absolute right-0 top-0 bottom-0 w-24 bg-gradient-to-l from-brand-sand dark:from-brand-dark via-brand-sand/80 dark:via-brand-dark/80 to-transparent z-10 pointer-events-none" />
                        )}

                        <div 
                            ref={carouselRef}
                            className="overflow-x-scroll scrollbar-hide relative"
                        >
                            <div className="flex gap-3 px-4 md:px-8">
                                {products.map((product) => (
                                    <Link
                                        key={product.id}
                                        to={`/product/${product.slug}`}
                                        className="flex-none"
                                        style={{ 
                                            width: `${itemWidth}px`,
                                        }}
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        <motion.div 
                                            whileHover={{ scale: 1.05 }}
                                            className="bg-white dark:bg-brand-dark rounded-lg shadow-md overflow-hidden relative"
                                        >
                                            <img 
                                                src={product.mainImage || product.defaultImages?.main || ''}
                                                alt={product.name}
                                                className={`w-full object-cover ${
                                                    isMobile ? 'h-32' : 'h-64'
                                                }`}
                                                loading="lazy"
                                                width={isMobile ? 300 : 600}
                                                height={isMobile ? 128 : 256}
                                            />
                                            <div className={`p-2 ${isMobile ? '' : 'p-4'}`}>
                                                <h3 className={`font-semibold text-black dark:text-white mb-1 truncate ${
                                                    isMobile ? 'text-sm' : 'text-lg mb-2'
                                                }`}>
                                                    {product.name}
                                                </h3>
                                                <p className={`text-black dark:text-white font-medium ${
                                                    isMobile ? 'text-sm' : 'text-base'
                                                }`}>
                                                    €{((product.price ?? product.basePrice) ?? 0).toFixed(2)}
                                                </p>
                                            </div>
                                            {product.oneOfKind && (
                                                <div className="absolute top-2 right-2 bg-brand-blue text-white text-xs px-2 py-1 rounded z-10">
                                                    One of a Kind
                                                </div>
                                            )}
                                            {product.variants && product.variants.length > 0 && (
                                                <div className="absolute top-2 left-2 bg-brand-blue text-white text-xs px-2 py-1 rounded z-10">
                                                    Multiple Options
                                                </div>
                                            )}
                                        </motion.div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RelatedProducts; 