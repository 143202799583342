import { createContext, useContext, useState, useEffect } from 'react';
import { cleanupCookies } from '../utils/cookieCleanup';

interface CookieConsentContextType {
  consent: {
    necessary: boolean;
    analytics: boolean;
    marketing: boolean;
    preferences: boolean;
  };
  updateConsent: (type: string, value: boolean) => void;
  savePreferences: () => void;
  hasInteracted: boolean;
}

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

export const CookieConsentProvider = ({ children }: { children: React.ReactNode }) => {
  const [consent, setConsent] = useState({
    necessary: true, // Always true as these are essential
    analytics: false,
    marketing: false,
    preferences: false,
  });
  const [hasInteracted, setHasInteracted] = useState(true);

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent');
    if (!storedConsent) {
      setHasInteracted(false);
    } else {
      try {
        const parsedConsent = JSON.parse(storedConsent);
        // Ensure all required properties exist
        setConsent({
          necessary: true,
          analytics: parsedConsent.analytics || false,
          marketing: parsedConsent.marketing || false,
          preferences: parsedConsent.preferences || false,
        });
      } catch (error) {
        console.error('Error parsing stored consent:', error);
        setHasInteracted(false);
      }
    }
  }, []);

  const updateConsent = (type: string, value: boolean) => {
    setConsent(prev => {
      const newConsent = {
        ...prev,
        [type]: value,
      };
      
      // Clean up cookies when consent is withdrawn
      cleanupCookies(newConsent);
      
      return newConsent;
    });
  };

  const savePreferences = () => {
    const consentTimestamp = new Date().toISOString();
    const consentData = {
      ...consent,
      timestamp: consentTimestamp,
      version: '1.0'
    };
    localStorage.setItem('cookieConsent', JSON.stringify(consentData));
    setHasInteracted(true);
  };

  return (
    <CookieConsentContext.Provider value={{ consent, updateConsent, savePreferences, hasInteracted }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
}; 