import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { trackPurchase } from '../components/MetaPixel';
import SEO from '../components/SEO';

interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  oneOfKind?: boolean;
  variantId?: string | null;
  slug: string;
  image: string;
}

interface TrackPurchaseData {
  content_ids: string[];
  contents: { id: string; quantity: number; }[];
  value: number;
  currency: string;
}

const Success: React.FC = () => {
  const { clearCart } = useCart();
  const [isClearing, setIsClearing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id');
  
  useEffect(() => {
    const processOrder = async () => {
      if (!session_id || emailSent) return;

      try {
        setError(null);
        
        const response = await fetch(`/api/get-session?session_id=${session_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch session details');
        }
        
        const data = await response.json();
        console.log('Session data:', data);

        if (!data.items || !Array.isArray(data.items)) {
          throw new Error('No items found in order');
        }

        const items = data.items as CartItem[];
        
        // Calculate totals using the items from the session
        const subtotal = items.reduce(
          (sum: number, item: CartItem) => sum + (item.price * item.quantity), 
          0
        );
        const shippingCostEuros = data.shipping_rate?.amount / 100 || 0;
        const total = subtotal + shippingCostEuros;

        // Send confirmation email
        const emailResponse = await fetch('/api/send-order-email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerEmail: data.customerEmail,
            orderDetails: {
              items,
              subtotal,
              shippingMethod: data.shipping_rate?.display_name || 'Standard Shipping',
              shippingCost: shippingCostEuros,
              total
            }
          }),
        });

        if (!emailResponse.ok) {
          throw new Error('Failed to send confirmation email');
        }

        // Format data for tracking with proper types
        const trackingData: TrackPurchaseData = {
          content_ids: items.map((item: CartItem) => item.id),
          contents: items.map((item: CartItem) => ({
            id: item.id,
            quantity: item.quantity
          })),
          value: total,
          currency: 'EUR'
        };

        // Track purchase
        trackPurchase(trackingData);
        
        setEmailSent(true);
        setIsClearing(true);

      } catch (error) {
        console.error('Error processing order:', error);
        setError(error instanceof Error ? error.message : 'An unexpected error occurred');
      }
    };

    processOrder();
  }, [session_id, emailSent]);

  // Only clear cart after email is sent
  useEffect(() => {
    if (isClearing && emailSent) {
      const timer = setTimeout(() => {
        clearCart();
        setIsClearing(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [isClearing, emailSent, clearCart]);

  if (isClearing) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-[80vw] flex items-center justify-center">
        <div className="text-center">
          <p className="text-lg">Processing your order...</p>
          {/* You can add a loading spinner here */}
        </div>
      </div>
    );
  }

  // Add error display to the UI
  return (
    <div className="container mx-auto px-4 py-8 max-w-[80vw]">
      {error ? (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <p className="font-bold">Error Processing Order</p>
          <p className="text-sm">{error}</p>
          <p className="text-sm mt-2">Don't worry, your order has been confirmed. Our team will contact you shortly.</p>
        </div>
      ) : (
        <div className="bg-brand-sand dark:bg-opacity-0 flex items-center justify-center p-4">
          <div className="max-w-md w-full bg-white dark:bg-brand-charcoal rounded-lg shadow-lg p-8 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <FontAwesomeIcon 
                icon={faCheckCircle} 
                className="text-green-500 text-5xl mb-6"
              />
              <h1 className="text-3xl font-bold text-brand-dark dark:text-white mb-4">
                Thank you for your order!
              </h1>
              <p className="text-gray-600 mb-6 dark:text-white">
                {emailSent 
                  ? "We've sent you a confirmation email with your order details."
                  : "We're processing your order..."}
              </p>
              <Link
                to="/shop"
                className="inline-block bg-brand-blue text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Continue Shopping
              </Link>
            </motion.div>
          </div>
          <SEO 
            title="Order Confirmed"
            description="Thank you for your purchase"
            noindex={true}
          />
        </div>
      )}
    </div>
  );
};

export default Success;