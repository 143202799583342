export const stockData: Record<string, number> = {
  'BORRE-DIJ-PLA-002': 1,
  'CIENP-AN-PLA-001': 1,
  'CIR-ARE-PLA-001': 1,
  'CROOK-ARRA-LAT-001': 3,
  'CROOK-ARRA-MOKU-002': 1,
  'CROOK-ARRA-PLA-002': 1,
  'CUBO-ARE-PLA-001': 2,
  'CUBO-ARE-PLA-002': 3,
  'CUBO-ARRA-PLA-001': 1,
  'CUBO-DIJ-PLA-001': 2,
  'CUBO-DIJ-PLA-002': 2,
  'ESPJ-AN-PLA-001': 6,
  'GALLO-SOL-BRO-001': 1,
  'GRANCHI-PUL-PLA-001': 1,
  'HUMO-ARE-PLA-001': 1,
  'HUMO-COL-PLA-001': 1,
  'LANGO-ARE-PB-001': 1,
  'LAVA-AN-PLA-001': 3,
  'LAVA-ARRA-GRAN-001-LAT': 1,
  'LAVA-ARRA-GRAN-001-PLA': 3,
  'LAVA-ARRA-LAT-MED-002': 1,
  'LAVA-ARRA-LAT-INY-001': 4,
  'LAVA-ARRA-MED-001-LAT': 4,
  'LAVA-ARRA-MED-001-PLA': 5,
  'LAVA-ARRA-TINY-001-PLA': 4,
  'LAVA-ARRA-TINY-001-LAT': 4,
  'NOPAL-ARE-LAT-001': 3,
  'NOPAL-ARE-LAT-002': 1,
  'PEPI-ARE-LAT-001': 0,
  'PEZ-AN-PLA-LAPIS-001': 1,
  'PEZ-ARE-PLA-001': 0,
  'PEZ-ARE-PLA-002': 2,
  'PEZ-ARE-PB-PRL-001': 0,
  'POLE-1-ARE-PB-001': 1,
  'POLE-1-2-ARE-PB-004': 1,
  'POLE-2-ARE-PB-001': 1,
  'RANCH-ARE-PLA-001': 1,
  'ROMBO-ARRA-PLA-001': 1,
  'RUIN-ARE-CER-001': 1,
  'SATIRO-ARE-PLA-001-LAT': 1,
  'SATIRO-ARE-PLA-001-PLA': 2,
  'TANGA-AN-LAT-001': 8,
}; 