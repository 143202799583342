import { Product } from '../types/product-variant';

interface EnhancedProductSchemaProps {
  product: Product;
  currentUrl: string;
}

const EnhancedProductSchema: React.FC<EnhancedProductSchemaProps> = ({ product, currentUrl }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.name,
    "description": product.description,
    "image": [
      `https://www.camilaparente.store${product.mainImage}`,
      `https://www.camilaparente.store${product.secondaryImage}`,
      `https://www.camilaparente.store${product.thirdImage}`
    ].filter(Boolean),
    "url": currentUrl,
    "sku": product.id,
    "brand": {
      "@type": "Brand",
      "name": "Camila Parente"
    },
    "offers": {
      "@type": "Offer",
      "url": currentUrl,
      "priceCurrency": "EUR",
      "price": product.variants ? product.basePrice : product.price,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": product.inStock ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
    },
    // Additional Meta catalog specific properties
    "productID": product.id,
    "category": product.category,
    "material": product.material,
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "collection",
        "value": product.collection
      },
      {
        "@type": "PropertyValue",
        "name": "oneOfKind",
        "value": product.oneOfKind || false
      }
    ]
  };

  return (
    <>
      <meta property="product:brand" content="Camila Parente" />
      <meta property="product:availability" content={product.inStock ? "in stock" : "out of stock"} />
      <meta property="product:condition" content="new" />
      <meta property="product:price:amount" content={String(product.variants ? product.basePrice : product.price)} />
      <meta property="product:price:currency" content="EUR" />
      <meta property="product:retailer_item_id" content={product.id} />
      <meta property="product:category" content={product.category} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </>
  );
};

export default EnhancedProductSchema; 