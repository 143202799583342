import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';

interface ShareButtonProps {
    url: string;
    title: string;
    description: string;
    image: string;
}

const ShareButton = ({ url, title, description, image }: ShareButtonProps) => {
    const [showShareMenu, setShowShareMenu] = useState(false);

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    text: description,
                    url
                });
            } catch (error) {
                if ((error as Error).name !== 'AbortError') {
                    setShowShareMenu(true);
                }
            }
        } else {
            setShowShareMenu(true);
        }
    };

    const shareLinks = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
        twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
        whatsapp: `https://wa.me/?text=${encodeURIComponent(`${title} ${url}`)}`,
        pinterest: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(image)}&description=${encodeURIComponent(title)}`
    };

    const handlePlatformShare = (platform: keyof typeof shareLinks) => {
        window.open(shareLinks[platform], '_blank', 'width=600,height=400');
        setShowShareMenu(false);
    };

    return (
        <div className="relative">
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleShare}
                className="flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-brand-charcoal rounded-lg hover:bg-gray-200 dark:hover:bg-brand-charcoal/80 transition-colors"
            >
                <FontAwesomeIcon icon={faShare} className="h-4 w-4" />
                <span>Share</span>
            </motion.button>

            <AnimatePresence>
                {showShareMenu && (
                    <>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black/20 z-40"
                            onClick={() => setShowShareMenu(false)}
                        />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9, y: 10 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0.9, y: 10 }}
                            className="absolute right-0 mt-2 p-2 bg-white dark:bg-brand-dark rounded-lg shadow-lg z-50 min-w-[200px]"
                        >
                            {Object.entries(shareLinks).map(([platform, _]) => (
                                <button
                                    key={platform}
                                    onClick={() => handlePlatformShare(platform as keyof typeof shareLinks)}
                                    className="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-brand-charcoal rounded flex items-center gap-3 capitalize"
                                >
                                    <FontAwesomeIcon 
                                        icon={
                                            platform === 'facebook' ? faFacebookF :
                                            platform === 'twitter' ? faTwitter :
                                            platform === 'whatsapp' ? faWhatsapp :
                                            faPinterest
                                        }
                                        className="h-4 w-4"
                                    />
                                    {platform}
                                </button>
                            ))}
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ShareButton; 