import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SEO from '../components/SEO';

const ProductFeed: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname === '/google-feed') {
      window.location.href = '/google-feed.tsv';
    } else {
      window.location.href = '/product-feed.csv';
    }
  }, [location]);

  return (
    <>
      <SEO 
        title="Product Feed"
        description="Product feed for external services"
        noindex={true}
      />
      {/* rest of component */}
    </>
  );
};

export default ProductFeed; 