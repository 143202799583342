// src/context/CartContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { stockData } from '../data/stock';

export interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  image: string;
  oneOfKind?: boolean; // Add oneOfKind flag
  slug: string; // Add slug to interface
  variantId?: string | null; // Add variantId to interface
}

interface CartContextType {
  items: CartItem[];
  email: string | null;
  setEmail: (email: string) => void;
  addItem: (item: CartItem) => void;
  removeItem: (id: string) => void;
  updateQuantity: (id: string, quantity: number) => void;
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<CartItem[]>(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(items));
  }, [items]);

  const addItem = (item: CartItem) => {
    const fullImageUrl = item.image.startsWith('http') 
      ? item.image 
      : `${window.location.origin}${item.image}`;

    setItems(currentItems => {
      const existingItem = currentItems.find(i => i.id === item.id);
      const currentStock = stockData[item.id] ?? 0;
      
      if (existingItem) {
        // Don't increase quantity for one of kind items or if stock limit reached
        if (item.oneOfKind || existingItem.quantity >= currentStock) {
          return currentItems;
        }
        return currentItems.map(i =>
          i.id === item.id ? { ...i, quantity: Math.min(i.quantity + 1, currentStock) } : i
        );
      }
      return [...currentItems, { ...item, image: fullImageUrl, quantity: Math.min(1, currentStock) }];
    });
  };

  const removeItem = (id: string) => {
    setItems(currentItems => currentItems.filter(item => item.id !== id));
  };

  const updateQuantity = (id: string, quantity: number) => {
    const currentStock = stockData[id] ?? 0;
    
    // If quantity is 0 or less, remove the item
    if (quantity <= 0) {
      removeItem(id);
      return;
    }

    // Don't exceed stock limit
    const validQuantity = Math.min(quantity, currentStock);

    setItems(currentItems =>
      currentItems.map(item => {
        // Don't update quantity for one of kind items
        if (item.id === id && item.oneOfKind) {
          return item;
        }
        return item.id === id ? { ...item, quantity: validQuantity } : item;
      })
    );
  };

  const clearCart = () => {
    setItems([]);
  };

  return (
    <CartContext.Provider value={{ 
      items, 
      email,
      setEmail,
      addItem, 
      removeItem, 
      updateQuantity, 
      clearCart 
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};